<template>
  <div class="home-container">
    <Navbar 
      align="start"
      :showHomeButton="false"
      :showBackButton="false"></Navbar> 
    <div class = "home-logo-container">
      <img class="home-logo" src="@/assets/img/logo/main-logo.png">
    </div>

    <PageButtons
      :buttons="buttons"
    ></PageButtons>

  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import PageButtons from '@/components/PageButtons.vue'

export default {
  title() {
    return this.$t('view.home.name')
  },
  data() {
    return {

    }
  },
  computed: {
    buttons() {
      return [
        {
          /* !!!! really important to use arrow functions here so that $router and locales
          can be used ('this' is not defined if a normal function is used). This is because 
          with arrow functions the this keyword always represents the object that defined the arrow 
          function. With a normal function, 'this' represents whatever object called the function*/
          clickCallback: () => {
            this.$router.push(this.$t('view.play.route'));
          },
          title: this.$t('view.home.playButton')
        },
        {
          clickCallback: () => {
            this.$router.push(this.$t('view.files.route'));
          },
          title: this.$t('view.files.name')
        }
      ]
    },
  },
  components: {
    Navbar,
    PageButtons,
  }
}

</script>
